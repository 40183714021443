<template>
  <div :href="strapi.UrlToAllBrands" class="overflow-hidden d-flex py-8">
    <Vue3Marquee :duration="60" :direction="'left'">
      <VImg v-for="logo in strapi.Logos" :key="logo.id" :src="logo?.formats?.small?.url || logo?.url" :alt="logo.name" height="70" width="250" class="carousel-image align-items mx-2" />
    </Vue3Marquee>
  </div>
</template>

<script>
import { Vue3Marquee } from 'vue3-marquee';

export default {
  name: 'CarouselLogos',
  components: { Vue3Marquee },
  props: {
    strapi: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      sizesCalculated: false
    };
  },
  methods: {
    getMeta(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = err => reject(err);
        img.src = url;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.carousel-image {
  object-fit: contain;
}
</style>
