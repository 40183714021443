<template>
  <div class="navigation-container" :class="{ 'bg-secondary': hasBackground, 'is-hidden': !isVisible }">
    <nav>
      <div class="d-flex" :class="{ 'border-white': !hasBackground }">
        <div class="container">
          <div class="border-b d-flex align-center justify-center h-80px">
            <a href="/" class="d-inline-block" style="line-height: 0" aria-label="Go to landing page"><Logo class="d-block" alt="Techship Inverse Logo - Click to return to the homepage" inverse width="170px" /></a>
            <SearchBar class="flex-grow-1 mx-3 mx-lg-10" />
            <MenuBar class="mr-7" :toggleTintLayer="toggleTintLayer" />
            <div class="d-flex">
              <MyAccountButton class="mr-1" :class="{ 'order-1 mr-3': $isNotLoggedIn }" />
              <CurrencyDropdown class="mr-1" :class="{ 'order-2': $isNotLoggedIn }" :toggleTintLayer="toggleTintLayer" />
              <CartButton class="order-3" />
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="bg-tint d-block" :class="{ visible: tintVisible }" />
  </div>
</template>

<script>
import SearchBar from '@Components/SearchBar.vue';
import MenuBar from '@Components/MenuBar.vue';
import MyAccountButton from '@Components/MyAccountButton.vue';
import CurrencyDropdown from '@Components/CurrencyDropdown.vue';
import CartButton from '@Components/CartButton.vue';

export default {
  name: 'DesktopNavigation',
  components: { SearchBar, MenuBar, MyAccountButton, CurrencyDropdown, CartButton },
  props: {
    isVisible: {
      type: Boolean,
      default: true
    },
    hasBackground: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      tintVisible: false
    };
  },
  methods: {
    toggleTintLayer(menu, visible) {
      if (visible) {
        this.activeMenu = menu;
        this.tintVisible = true;
      } else if (this.activeMenu === menu) {
        this.activeMenu = null;
        this.tintVisible = false;
      }
    }
  }
};
</script>

<style lang="scss">
.bg-tint {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  z-index: 10;
  opacity: 0;
  background: rgba(0, 0, 0, 0.25);
  transform: translateY(-100%);
  transition:
    transform 0s ease 0.2s,
    opacity 0.2s ease-in-out;

  &.visible {
    transform: translateY($menu-height-desktop);
    opacity: 1;
    transition:
      transform 0s ease 0.2s,
      opacity 0.2s ease-in-out 0.2s;
  }
}
</style>
