<template>
  <VMenu location-strategy="connected" open-on-click transition="no-transition" class="h-100" location="bottom end" origin="top end" @update:modelValue="handleMenuVisibility">
    <template #activator="{ props }">
      <div class="d-flex align-center justify-space-between h-100 menu-bar">
        <Button v-for="(item, index) in items" :key="index" :icon="icon" size="8px" plainNoAnimation variant="plain" color="white" v-bind="props" class="menu-button px-0 pr-4 pl-2 h-100 d-flex justify-center align-center text-white paragraph bold mb-0" @click="setActiveMenu(index)">
          {{ item.title }}
          <!-- <VIcon :icon="icon" size="8px"  /> -->
        </Button>
      </div>
    </template>
    <div ref="menu" class="menu">
      <DropdownMenu v-if="activeMenu" :items="activeMenu.children" :icon="icon" :index="activeMenuIndex" />
    </div>
  </VMenu>
</template>

<script>
import DropdownMenu from '@Components/DropdownMenu.vue';

export default {
  name: 'MenuBar',
  components: { DropdownMenu },
  props: {
    toggleTintLayer: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      navigation: this.$dom.application.navigation,
      items: [],
      icon: 'fas fa-chevron-down',
      activeMenu: null,
      activeMenuIndex: null
    };
  },
  created() {
    this.$utils.each(this.navigation, (value, index) => {
      if (this.$utils.isEmpty(value.children)) return;
      this.$utils.each(value.children, (item, index) => {
        this.items.push(item);
      });
    });
  },
  methods: {
    setActiveMenu(index) {
      if (this.activeMenuIndex !== index) {
        this.activeMenuIndex = index;
        this.activeMenu = this.items[index];
      }
    },
    handleMenuVisibility(isVisible) {
      this.toggleTintLayer(this, isVisible);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-menu {
  :deep(.v-overlay__content) {
    top: 98px !important;
    transform: translateX(70px);
    min-width: 500px !important;
  }
}

.menu-button {
  position: relative;
}
</style>
