/**
 * SEO Mixin for updating meta tags and titles.
 * Provides methods to dynamically set or update the meta tags and document title based on SEO data.
 */
export default {
  methods: {
    /**
     * Sets or updates the robots meta tag to control page indexing by search engines.
     * The content is set based on a value from the CMS, defaulting to allow indexing.
     */
    updateRobotsMeta() {
      const seo = this.strapi?.SEO || this.strapi?.related?.SEO || {};
      const indexByGoogle = this.$utils.isNil(seo.indexByGoogle) ? true : seo.indexByGoogle;
      const content = indexByGoogle ? 'index, follow' : 'noindex, nofollow';
      this.setMetaTag('name', 'robots', content);
    },

    /**
     * Updates the meta tags for description, keywords, and the document title.
     * Utilizes SEO data provided by the CMS, with default values as a fallback.
     */
    updateMetaTags() {
      const seo = this.strapi?.SEO || this.strapi?.related?.SEO || {};
      const globalSEO = window.Techship.application.globalSEO || {};
      this.setMetaTag('name', 'description', seo.metaDescription || globalSEO.metaDescription);
      this.setMetaTag('name', 'keywords', seo.keywords);
      this.setTitle(seo.metaTitle || document?.title || globalSEO.metaTitle); 
    },

    /**
     * Creates or updates a meta tag in the document head. If the content is empty, existing meta tags are removed.
     * @param {string} attrName - The attribute name of the meta tag (e.g., 'name' or 'property').
     * @param {string} attrValue - The value for the attribute (e.g., 'description', 'keywords').
     * @param {string|null} content - The content for the meta tag. If null, the tag is removed.
     */
    setMetaTag(attrName, attrValue, content) {
      if (document) {
        let metaTag = document.querySelector(`meta[${attrName}="${attrValue}"]`);
        // Check if the meta tag exists and has content
        if (metaTag && metaTag.getAttribute('content')) {
          // Meta tag exists and has content, do not overwrite
          return;
        }

        if (content) {
          if (!metaTag) {
            metaTag = document.createElement('meta');
            metaTag.setAttribute(attrName, attrValue);
            document.getElementsByTagName('head')[0].appendChild(metaTag);
          }
          metaTag.setAttribute('content', content);
        } else if (metaTag) {
          // If content is not provided and tag exists, remove it
          metaTag.remove();
        }
      }
    },

    /**
     * Sets the document title. If the title is not provided, it defaults to the current document title.
     * @param {string} title - The title to be set for the document.
     */
    setTitle(title) {
      if (title && document) {
        const suffix = ' | Techship';
        if (!title.endsWith(suffix)) {
          title += suffix;
        }
        document.title = title;
      }
    }
  },

  /**
   * Vue lifecycle hook - created.
   * Called after the instance is created, this hook updates the meta tags including robots meta and page title.
   */
  created() {
    this.updateMetaTags();
    this.updateRobotsMeta();
  }
};
