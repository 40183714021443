<template>
  <VExpansionPanels :variant="variant">
    <slot />
  </VExpansionPanels>
</template>

<script>
export default {
  name: 'ExpansionPanels',
  props: {
    variant: {
      type: String,
      default: 'accordion'
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.v-expansion-panel:first-child) {
  padding-top: 34px;
  @include is-screen-md() {
    padding-top: 64px;
  }
  &::after {
    top: 34px !important;
    @include is-screen-md() {
      top: 64px !important;
    }
  }
}

:deep(.v-expansion-panel:last-child) {
  padding-bottom: 34px;
  @include is-screen-md() {
    padding-bottom: 64px;
  }

  &::before {
    border-bottom-style: solid;
    border-bottom-width: thin;
    content: '';
    position: absolute;
    border-bottom-color: $color-grey-light;
    transition: 0.3s opacity cubic-bezier(0.4, 0, 0.2, 1);
    left: 34px !important;
    right: 34px !important;
    bottom: 34px;
    @include is-screen-md() {
      left: 64px !important;
      right: 64px !important;
      bottom: 64px;
    }
  }
}

:deep(.v-expansion-panel::after) {
  border-top-style: solid;
  border-top-width: thin;
  content: '';
  left: 34px !important;
  position: absolute;
  right: 34px !important;
  top: 0;
  border-top-color: $color-grey-light;
  transition: 0.3s opacity cubic-bezier(0.4, 0, 0.2, 1);
  @include is-screen-md() {
    left: 64px !important;
    right: 64px !important;
  }
}
</style>
