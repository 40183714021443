<script setup>
import StockIndicator from '@/components/StockIndicator.vue';

const props = defineProps({
  items: {
    type: Array,
    required: true
  }
});

const model = defineModel();

const handleClick = option => {
  model.value = option.code;
};

const stripTags = text => text?.replace(/<[^>]+>/g, '') ?? '';
</script>
<template>
  <div class="h-max-200px overflow-y-auto mr-n4">
    <VRadioGroup v-model="model" hide-details defaults-target="code">
      <div class="d-flex flex-column gap-8">
        <Card v-for="option in props.items" :key="option.code" class="rounded-lg bg-grey-background px-3 py-2 mr-2" @click="handleClick(option)">
          <div class="d-flex align-center justify-space-between relative">
            <div class="flex-grow-1 overflow-hidden">
              <div class="overflowText">
                {{ option?.code }} – {{ option?.vendor_part_number }}
                <span v-if="option?.shop_description">– {{ stripTags(option.shop_description) }}</span>
              </div>
              <StockIndicator textLocation="left" :stockStatus="option.stockStatus" noBold />
              <div v-if="option?.salesReason" class="overflowText bold text-secondary">{{ option.salesReason.name }}</div>
            </div>
            <VRadio v-if="items?.length > 1" class="flex-shrink-0 flex-grow-0 mx-0" :value="option.code" :ripple="false" density="compact" />
          </div>
        </Card>
      </div>
    </VRadioGroup>
  </div>
</template>

<style lang="scss" scoped>
.hidden {
  visibility: hidden;
}

.overflowText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ml-3 {
  margin-left: 1rem;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $color-white;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-grey-dark;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-grey-darkest;
  border-radius: 5px;
}
</style>
