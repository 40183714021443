<template>
  <MobileNavigation v-if="isMobile" :hasBackground="hasBackground" :isVisible="isVisible" />
  <DesktopNavigation v-else :hasBackground="hasBackground" :isVisible="isVisible" />
</template>

<script>
import DesktopNavigation from '@Components/DesktopNavigation.vue';
import MobileNavigation from '@Components/MobileNavigation.vue';
import { throttle } from 'throttle-debounce';

export default {
  name: 'Navigation',
  components: { DesktopNavigation, MobileNavigation },
  data() {
    return {
      loading: false,
      ...this.$controller.data,
      hasBackground: false,
      hasDynamicBackground: true,
      isVisible: true,
      previousScrollPosition: 0,
      hideNavigation: false
    };
  },
  created() {
    this.$mitt.on('hideNavigation', bool => {
      this.hideNavigation = bool;
      if (!bool) {
        this.isVisible = true;
      }
    });
  },
  mounted() {
    const dataMenuBgElement = document.querySelector('[data-menu-bg]');
    this.hasBackground = dataMenuBgElement && dataMenuBgElement.dataset.menuBg === 'true';
    this.hasDynamicBackground = !this.hasBackground;
    this.checkScrollPosition();
    window.addEventListener('scroll', this.throttleCheckScrollPosition);
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.throttleCheckScrollPosition);
  },
  methods: {
    checkScrollPosition(event) {
      const scrollPosition = window.scrollY;
      const goingDown = scrollPosition > this.previousScrollPosition;

      if (this.hideNavigation || (goingDown && this.isVisible && scrollPosition > 80)) {
        this.isVisible = false;
      } else if ((!goingDown && !this.isVisible) || scrollPosition <= 80) {
        this.isVisible = true;
      }
      this.previousScrollPosition = scrollPosition;

      if (this.hasDynamicBackground) {
        if (!this.hasBackground && scrollPosition > 80) {
          this.hasBackground = true;
        } else if (this.hasBackground && scrollPosition <= 80) {
          this.hasBackground = false;
        }
      }
    },
    throttleCheckScrollPosition(event) {
      throttle(300, this.checkScrollPosition(event));
    }
  }
};
</script>

<style lang="scss" scoped>
.navigation-container {
  height: $menu-height-desktop;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 11;
  transform: translateY(0);
  transition:
    background-color 0.3s $transition-timing,
    transform 0.5s ease-in-out;

  // &:hover {
  // background-color: $color-secondary;
  // }

  &.is-hidden {
    transform: translateY(-100%);
  }

  &.bg-secondary {
    transition:
      background-color $transition-duration $transition-timing 0.5s,
      transform 0.5s ease-in-out;
  }
}

.navigation-container,
.navigation-container > * {
  box-sizing: border-box;
}
</style>
