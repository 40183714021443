<template>
  <div class="container filter-top h-65px py-3 gap-2">
    <div class="d-flex align-center">
      <div class="visibility-toggle">
        <ToggleButton id="filterVisibility" ref="filterVisibility" :icon="strapi.HideFiltersMobile.Icon" :labelPositionRight="true" :inactiveIcon="strapi.ShowFiltersMobile.Icon" :inactiveLabel="strapi.ShowFiltersMobile.Label" :label="strapi.HideFiltersMobile.Label" @onToggle="onToggle" />
      </div>
      <div v-if="!filterVisible && activeFiltersCount !== 0" class="d-md-none ml-1 pa-0 active-filters-count">{{ activeFiltersCount }}</div>
      <!-- <div v-if="!filterVisible" class="expand-toggle" :class="{ '-visible': filterVisible }">
        <ToggleButton id="expandAll" :active="expandAll" class="mr-5" :inactiveLabel="strapi.ExpandAllFilters" :label="strapi.CollapseAllFilters" labelPositionRight @onToggle="onToggle" />
      </div> -->
    </div>
    <Button iconButton class="clear-filter-btn" variant="text" :class="{ '-visible': filterVisible, '-enabled': clearFilterVisible }" :onClick="onClearFilter">
      <i :class="strapi.ClearFilter.Icon" />
      {{ strapi.ClearFilter.Label }}
      <div class="active-filters-count ml-1">{{ activeFiltersCount }}</div>
    </Button>
    <!-- <div class="drop-down ml-auto d-flex -visible"> -->
    <div v-if="!filterVisible" class="drop-down ml-auto d-flex" :class="{ '-visible': !filterVisible }">
      <label class="my-auto">{{ strapi.SortingLabel }}:</label>
      <Select id="sort" v-model="sortOn" return-object :items="sortItemsMergeWithStrapi" item-title="KeyDisplayName" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductListingFilterTop',
  props: {
    sortOnKey: {
      type: String,
      default: 'sort'
    },
    sortOptions: {
      type: Array,
      default: null
    },
    expandAll: {
      type: Boolean,
      default: true
    },
    compare: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    clearFilterVisible: {
      type: Boolean,
      default: true
    },
    strapi: {
      type: Object,
      default: () => {}
    },
    activeFiltersCount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isAllExpanded: this.expandAll,
      compareActive: this.compare,
      filterVisible: false,
      isClearFilterVisible: this.clearFilterVisible,
      sortItemsMergeWithStrapi: [],
      sortOn: {
        Key: 'popular',
        KeyDisplayName: 'Popular'
      }
    };
  },
  watch: {
    expandAll() {
      this.isAllExpanded = this.expandAll;
      return this.isAllExpanded;
    },
    clearFilterVisible() {
      this.isClearFilterVisible = this.clearFilterVisible;
      return this.isClearFilterVisible;
    },
    compare() {
      this.compareActive = this.compare;
      return this.compareActive;
    },
    sortOn() {
      this.$emit('onSortChange', { sortOnKey: this.sortOnKey, sortType: this.sortOn?.Key });
    }
  },
  created() {
    this.sortOptions?.forEach((item, index) => {
      const strapiItem = this.strapi?.SortingOptions?.find(strapiItem => strapiItem.Key?.toLowerCase() === item.toLowerCase());
      if (strapiItem) {
        this.sortItemsMergeWithStrapi.push({ ...strapiItem });
        return;
      }
      this.sortItemsMergeWithStrapi.push({ Key: item, KeyDisplayName: item });
    });

    const searchURL = new URL(window.location);
    if (searchURL.searchParams.has('sort')) {
      this.sortItemsMergeWithStrapi?.forEach((item, index) => {
        if (item.Key.toLowerCase() === searchURL.searchParams.get('sort').toLowerCase()) {
          this.sortOn = item;
        }
      });
    }
  },
  methods: {
    onToggle(data) {
      switch (data.id.toString()) {
        case 'expandAll':
          this.$emit('onExpandAll', { isActive: data.isActive });
          break;
        case 'filterVisibility':
          this.filterVisible = data.isActive;
          this.$emit('onFilterVisibility', { isActive: data.isActive });
          break;
        case 'compare':
          this.$emit('onCompare', { isActive: data.isActive });
          break;
        default:
          break;
      }
    },
    onClearFilter() {
      this.isClearFilterVisible = false;
      this.$emit('onClearFilter');
    },
    resetShowFilterButton() {
      this.$refs.filterVisibility.isActive = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-top {
  display: flex;
  border-bottom: 1px solid $color-grey-light;
}

.drop-down {
  display: none;
  &.-visible {
    display: block;
  }
  @include is-screen-md() {
    display: block;
  }
}

.visibility-toggle {
  display: block;
  @include is-screen-md() {
    display: none;
  }
}

.clear-filter-btn {
  display: none;
  line-height: 25px;
  margin-left: auto;

  &.-visible.-enabled {
    display: block;
  }
  @include is-screen-md() {
    margin-left: 0;
    &.-enabled {
      display: block;
    }
  }
}
.expand-toggle {
  display: none;
  &.-visible {
    display: block;
    margin-left: 20px;
  }
  @include is-screen-md() {
    display: block;
    margin-left: 0;
  }
}

.compare-toggle {
  display: none;
  &.-visible {
    display: block;
  }
}
.active-filters-count {
  display: inline-grid;
  height: 24px;
  border-radius: 12px;
  line-height: 24px;
  font-weight: 600 !important;
  font-size: 12px;
  min-width: 24px;
  padding-left: 4px;
  padding-right: 6px;
  background-color: $color-primary;
  color: $color-white;
  text-align: center;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0);
}
</style>
