<template>
  <!-- <div class="bg-grey-background w-100 pb-5 pb-md-10 pb-lg-14"> -->
  <div class="bg-grey-background w-100">
    <div ref="container" class="productListing container mobile-padding">
      <div ref="headspace" class="headspace" :class="{ '-enabled': sticky }" />
      <div ref="filterTop" class="filter-top bg-grey-background max-h-80px" :class="[scrollClass, { '-sticky': sticky }]">
        <ProductListingFilterTop ref="filterTopComp" :strapi="strapi" :clearFilterVisible="hasFilters" :activeFiltersCount="activeFiltersCount" :compare="compareActive" :expandAll="expandAllButtonState" :sortOptions="sortingStrategies" sortOnKey="sort" @onExpandAll="onExpandAll" @onSortChange="onSortUpdate" @onFilterVisibility="onFilterVisibilityToggle" @onClearFilter="clearFilter" />
      </div>
      <div class="content grid">
        <div class="grid-item filter-panel -col-span-3 bg-grey-background pb-2 mt-md-4" :class="{ '-sticky': sticky, '-visible': filterVisible }">
          <FilterExpandableBlock v-if="categoryLinks && showLinkList" class="mt-4" :expanded="isAllExpanded" :label="categoryLabel || strapi?.SubcategoryFallbackCollectiveName">
            <LinkList :data="categoryLinks" />
          </FilterExpandableBlock>
          <hr v-if="showLinkList" class="divider my-3" />
          <FilterExpandableBlock v-for="(filterAttribute, index) in filtersList" ref="filterBlocks" :key="filterAttribute.key_name" class="py-2" :expanded="isAllExpanded" :label="filterAttribute.name" :unit="filterAttribute.unit">
            <FilterAutocomplete v-if="filterAttribute.type?.toLowerCase() === 'string'" :ref="`filterBlock-${index}`" :data="filterAttribute" @onFilterUpdate="onAutocompleteUpdate" />
            <FilterRangeSlider v-if="filterAttribute.type?.toLowerCase() === 'range' || filterAttribute.type?.toLowerCase() === 'numeric'" :ref="`filterBlock-${index}`" :data="filterAttribute" :min="Math.floor(filterAttribute?.range.min)" :max="Math.ceil(filterAttribute?.range.max)" @onFilterUpdate="onRangeUpdate" />
            <FilterButtonBoolean v-if="filterAttribute.type?.toLowerCase() === 'boolean'" :ref="`filterBlock-${index}`" class="pt-2" :data="filterAttribute" @onFilterUpdate="onBooleanUpdate" />
          </FilterExpandableBlock>
          <div class="text-center mt-5">
            <Button class="mx-auto" @click="showAllFilters = !showAllFilters">{{ showFilterText }}</Button>
          </div>
        </div>
        <div class="py-4 py-md-8 grid-item -col-span-12 -col-span-md-9">
          <div class="d-flex flex-column gap-12 mx-3">
            <ProductRow v-for="product in fetchedProducts.products" :key="product.id" :name="product.name" :product="product" :loading="loading" />
            <div v-if="fetchedProducts.products.length == 0 && !loading" class="text-center mb-25">
              <h2 class="text-grey">{{ strapi.noProductsFoundText || 'The filter does not match any products' }}</h2>
            </div>
          </div>
          <Pagination v-show="showPagnation" class="mt-8" :disabled="loading" :modelValue="paginationPage" :totalVisible="5" :length="totalPages" @update:modelValue="onNewPage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductListingFilterTop from '@Components/productListing/ProductListingFilterTop.vue';
import FilterExpandableBlock from '@Components/productListing/FilterExpandableBlock.vue';
import ProductRow from '@Components/ProductRow.vue';
import Pagination from '@Components/productListing/Pagination.vue';
import LinkList from '@Components/productListing/LinkList.vue';
import FilterRangeSlider from '@Components/productListing/FilterRangeSlider.vue';
import FilterButtonBoolean from '@Components/productListing/FilterButtonBoolean.vue';
import FilterAutocomplete from '@Components/productListing/FilterAutocomplete.vue';
import { debounce } from '@Utils/default';
import axios from 'axios';

export default {
  name: 'ProductListing',
  components: { ProductListingFilterTop, FilterExpandableBlock, ProductRow, Pagination, LinkList, FilterAutocomplete, FilterRangeSlider, FilterButtonBoolean },
  props: {
    categories: {
      type: Object,
      default: () => {}
    },
    showLinkList: {
      type: Boolean,
      default: true
    },
    filterAttributes: {
      type: Object,
      default: () => {}
    },
    sortingStrategies: {
      type: Array,
      default: null
    },
    strapi: {
      type: Object,
      default: () => {}
    },
    categoryLabel: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      scrollClass: '',
      activeFiltersCount: 0,
      loading: false,
      sticky: false,
      sortOn: '',
      compareActive: false,
      offsetTopBar: 0,
      filterVisible: false, // Mobile only
      expandAllButtonState: true,
      isAllExpanded: true,
      hasFilters: false,
      showAllFilters: false,
      loadingProducts: { products: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}] },
      fetchedProducts: { products: [] },
      paginationPage: 1,
      cancelTokenSource: null,
      scrollLock: false,
      scrollY: 0 // Capture the vertical scroll position
    };
  },
  computed: {
    filtersList() {
      //Hide all filters without a key, as it's needed for Backend filtering
      const newFilterAttributes = this.filterAttributes; //.filter(filterAttribute => this.$utils.isNotEmpty(filterAttribute.key_name));
      if (this.showAllFilters) {
        return newFilterAttributes;
      }
      return newFilterAttributes.filter(filterAttribute => filterAttribute.isPrimary);
    },
    showFilterText() {
      return this.showAllFilters ? this.strapi.ShowLessFilters : this.strapi.ShowMoreFilters;
    },
    showPagnation() {
      return this.fetchedProducts?.totalPages > 1;
    },
    totalPages() {
      return this.fetchedProducts?.totalPages === 0 || this.$utils.isEmpty(this.fetchedProducts?.totalPages) ? 0 : this.fetchedProducts?.totalPages;
    },
    categoryLinks() {
      return this.categories.map(category => {
        return {
          ...category,
          route: category.route.publicShow
        };
      });
    }
  },
  watch: {
    filterVisible(newVal) {
      this.toggleBodyScrollLock(newVal);
    }
  },
  async created() {
    const searchURL = new URL(window.location);
    if (searchURL.searchParams.has('page')) {
      this.paginationPage = parseInt(searchURL.searchParams.get('page'));
    }
    this.hasFiltersCheck();
    window.addEventListener('scroll', () => {
      this.onScroll();
    });
    window.addEventListener('resize', () => {
      this.onResize();
    });
    await this.getProducts();
  },
  mounted() {
    this.offsetTopBar = this.$refs.headspace.offsetTop;
    this.countActiveFilters();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    onScroll() {
      if (this.filterVisible) {
        // console.log('Filter is visible, returning');
        // console.log('Filter is visible', window.scrollY);
        return;
      }
      // console.log('Filter is not visible, scrolling');
      if (!this.sticky) {
        this.offsetTopBar = this.$refs.headspace.offsetTop;
      }

      if (window.scrollY >= this.offsetTopBar) {
        // console.log('Setting sticky to true');
        // console.log(window.scrollY, this.offsetTopBar);
        this.sticky = true;
      } else {
        // console.log('Setting sticky to false');
        // console.log(window.scrollY, this.offsetTopBar);
        this.sticky = false;
      }
    },
    handleScroll() {
      const currentScrollY = window.scrollY;
      if (currentScrollY > this.scrollY) {
        // Scrolling down
        this.scrollClass = '-scroll-down';
      } else if (this.filterVisible) {
        this.scrollClass = '-scroll-down';
      } else {
        // Scrolling up
        this.scrollClass = '-scroll-up';
      }
      this.scrollY = currentScrollY;
    },
    countActiveFilters() {
      const searchURL = new URL(window.location);
      let tempCount = 0;
      searchURL.searchParams.forEach((value, key) => {
        if (this.filterAttributes.find(filterAttribute => filterAttribute.key_name === key)) {
          tempCount++;
        }
      });
      this.activeFiltersCount = tempCount;
    },
    onResize() {
      this.offsetTopBar = this.$refs.headspace.offsetTop;

      if (this.isNotMobile) {
        this.sticky = false;
        this.filterVisible = false;
        this.setHideNavigation(false);
        this.$refs.filterTopComp.resetShowFilterButton();
      }
    },
    toggleBodyScrollLock(lock) {
      if (lock) {
        this.scrollY = window.scrollY; // Save the scroll position

        const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
        const body = document.body;

        // Apply styles to lock scroll without moving to top
        body.style.position = 'fixed';
        body.style.top = `-${this.scrollY}px`;
        body.style.width = '100%';
        body.style.overflowY = 'scroll';
        body.style.paddingRight = `${scrollBarWidth}px`; // Prevent layout shift
      } else {
        const body = document.body;

        // Remove styles and revert back to the original scroll position
        body.style.position = '';
        body.style.top = '';
        body.style.overflowY = '';
        body.style.paddingRight = '';

        window.scrollTo(0, this.scrollY); // Restore the scroll position
      }
    },
    onFilterVisibilityToggle(data) {
      if (data.isActive) {
        if (!this.sticky) {
          this.$gsap.to(window, {
            duration: 0.8,
            scrollTo: this.$refs.filterTop.offsetTop + 10,
            onComplete: () => {
              this.sticky = true;
              this.setHideNavigation(true);
              this.filterVisible = true;
            }
          });
        } else {
          this.setHideNavigation(true);
          this.filterVisible = true;
        }
      } else {
        this.filterVisible = false;
        this.sticky = false;
        this.setHideNavigation(false);
      }
    },
    onExpandAll(data) {
      this.isAllExpanded = data.isActive;
      this.expandAllButtonState = this.isAllExpanded;
    },
    setResetPage() {
      this.paginationPage = 1;
      this.updateURLParameter('page', this.paginationPage);
    },
    async onSortUpdate(data) {
      const searchURL = new URL(window.location);
      searchURL.searchParams.set(data.sortOnKey, data.sortType);
      window.history.pushState({}, '', searchURL);
      await this.getProducts();
    },
    onAutocompleteUpdate(data) {
      const searchURL = new URL(window.location);
      if (data.selected.length > 0) {
        searchURL.searchParams.set(data.filterKey, data.selected.map(item => item.id).toString());
      } else {
        searchURL.searchParams.delete(data.filterKey);
      }
      this.onFilterChange(searchURL);
    },
    onRangeUpdate(data) {
      const searchURL = new URL(window.location);
      if (data.selected.length === 2) {
        searchURL.searchParams.set(data.filterKey, data.selected.toString());
      }
      this.onFilterChange(searchURL);
    },
    onBooleanUpdate(data) {
      const searchURL = new URL(window.location);
      if (data.selected !== null) {
        searchURL.searchParams.set(data.filterKey, data.selected);
      } else {
        searchURL.searchParams.delete(data.filterKey);
      }
      this.onFilterChange(searchURL);
    },
    clearFilter() {
      this.filtersList.forEach((_, index) => {
        this.$refs[`filterBlock-${index}`][0]?.clear();
      });
      const searchURL = new URL(window.location);
      searchURL.searchParams.forEach((_, key) => {
        if (key !== 'sort' && key !== 'page') {
          searchURL.searchParams.delete(key);
        }
      });
      this.onFilterChange(searchURL);
    },
    async getProducts() {
      // Cancel the previous request
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel('Operation canceled due to new request.');
      }

      // Create a new CancelToken
      this.cancelTokenSource = axios.CancelToken.source();

      const searchURL = new URL(window.location);
      this.loading = true;

      this.fetchedProducts = this.loadingProducts;

      // Pass the cancelTokenSource.token to your request
      const response = await this.get(`${searchURL?.pathname}getProducts/${searchURL?.search}`, {}, this.cancelTokenSource.token);
      if (response) {
        this.fetchedProducts = response;
        this.paginationPage = this.fetchedProducts.page;
        this.updateURLParameter('page', this.paginationPage);
        this.loading = false;
      }
    },
    async onNewPage(page) {
      this.paginationPage = page;
      this.updateURLParameter('page', page);
      this.$gsap.to(window, { duration: 0.8, scrollTo: this.$refs.container.offsetTop - 75 });
      await this.getProducts();
    },
    debounceGetProducts: debounce(async function () {
      await this.getProducts();
    }, 100),
    async onFilterChange(searchURL) {
      window.history.pushState({}, '', searchURL);
      this.setResetPage();
      this.hasFiltersCheck();
      this.countActiveFilters();
      this.debounceGetProducts();
    },
    hasFiltersCheck() {
      const searchURL = new URL(window.location);
      this.hasFilters = false;
      searchURL.searchParams.forEach((value, key) => {
        if (key !== 'sort' && key !== 'page') {
          this.hasFilters = true;
          return;
        }
      });
      return;
    }
  }
};
</script>

<style lang="scss" scoped>
.divider {
  border: 1px solid $color-grey-light;
  border-radius: 2px;
}

.headspace {
  position: relative;
  visibility: hidden;
  height: 0;
  &.-enabled {
    visibility: visible;
    height: 60px;

    @include is-screen-md() {
      visibility: hidden;
      height: 0;
    }
  }
}

.filter-panel {
  position: fixed;
  left: -100%; /* Offscreen by default on mobile */

  &.-sticky {
    width: 100%;
    height: calc(100% - 65px);
    padding: 0 40px 65px 40px;
    top: $menu-height-desktop;
    z-index: 11;
    transition: left 0.6s ease-in-out;
    overflow-y: auto;

    &.-visible {
      left: 0; /* Slide in */
    }

    @include is-screen-md() {
      position: relative;
      left: auto;
      height: auto;
      top: 0;
      padding: 0;
    }
  }
  @include is-screen-md() {
    position: relative;
    left: 0;
  }
}

// .filter-panel {
//   transform: translateX(-100vw);
//   position: fixed;

//   &.-sticky {
//     position: fixed;
//     width: 100%;
//     height: calc(100% - 65px);
//     padding: 0 40px 65px 40px;
//     top: $menu-height-desktop;
//     left: 0;
//     z-index: 10;
//     transition: transform 0.6s ease-in-out;
//     overflow-y: auto;

//     &.-visible {
//       transform: translateX(0);
//     }

//     @include is-screen-md() {
//       position: relative;
//       transform: translateX(0);
//       height: auto;
//       top: 0px;
//       padding: 0 0;
//     }
//   }
//   @include is-screen-md() {
//     position: relative;
//     transform: translateX(0);
//   }
// }

.filter-top {
  // padding: 0 20px;
  &.-sticky {
    position: fixed;
    width: 100%;
    padding: 15px 30px 0 30px;
    top: 0;
    left: 0;
    z-index: 11;
    @include is-screen-md() {
      position: relative;
      padding: 0 0;
    }
    &.-scroll-up {
      transform: translateY($menu-height-desktop);
      transition: transform 0.5s ease-in-out;
    }
    &.-scroll-down {
      transform: translateY(0);
      transition: transform 0.5s ease-in-out;
    }
  }
  @include is-screen-md() {
    padding: 0 0;
  }
}

@include max-screen-sm() {
  .mobile-padding {
    padding: 0 0px !important;
  }
}
</style>
