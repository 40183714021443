<script setup>
import { useWindowTechship } from '@/composables/useWindowTechship';

const props = defineProps({
  product: {
    type: Object,
    required: true
  },
  priceInfo: {
    type: String,
    required: true
  }
});

const { data } = useWindowTechship();
</script>

<template>
  <a v-if="props.priceInfo == 'replacement'" :href="props.product?.replacementArticle.variant?.route?.publicShow || props.product?.replacementArticle?.route?.publicShow">
    <div class="mt-4 mt-md-0">
      <p class="-no-margin text-grey text-center line-clamp-2 line-clamp-md-4">
        {{ data?.application?.productMessage?.SuggestedReplacementProductLabel }}:
        <br class="d-md-none" />
        <a>{{ props.product.replacementArticle.name }}</a>
      </p>
    </div>
  </a>
  <p v-else-if="props.priceInfo === 'contact'" class="-no-margin text-grey text-center" v-html="data?.application?.productMessage?.ContactSalesLabel" />
</template>
