<template>
  <div class="d-flex align-center">
    <VMenu v-if="!isMobile" open-on-click location-strategy="connected" location="bottom center" @update:modelValue="handleMenuVisibility">
      <template #activator="{ props }">
        <Button v-bind="props" iconButton aria-label="open currency" class="currency-button" :icon="currency.icon" color="white" iconSize="20px" @click="setDesktopDropdownActive" />
      </template>
      <div ref="menu" class="listContainer">
        <VList class="bg-secondary d-flex flex-column align-center justify-center px-3 py-6 w-170px" rounded="lg">
          <span class="arrow d-block w-20px h-0" />
          <VListItem v-for="(currency, index) in currencies" :key="index" @click="setCurrency(currency)">
            <div class="d-flex flex-sm-row align-center">
              <VIcon :icon="currency.icon" size="x-small" class="pr-5 pl-2 text-primary" />
              <VListItemTitle>{{ currency.code }}</VListItemTitle>
            </div>
          </VListItem>
        </VList>
      </div>
    </VMenu>
    <div v-if="isMobile" class="w-100">
      <div class="d-flex justify-space-between align-center">
        <Button aria-label="show currency" plainNoAnimation color="white" class="w-100 d-flex justify-space-between" @click="showMobileCurrency">
          <div class="d-flex align-center">
            <VIcon :icon="currency.icon" size="24px" class="mr-4 text-primary" />
            <p class="mb-0">Currency: {{ currency.code }}</p>
          </div>
          <VIcon icon="fas fa-chevron-down" class="text-primary" size="16px" />
        </Button>
      </div>
      <VList class="bg-secondary flex-column d-none" :class="{ 'd-flex': showCurrency === true }">
        <VListItem v-for="(currency, index) in currencies" :key="index" class="pl-0 pr-0" @click="setCurrency(currency)">
          <div class="d-flex align-center hover">
            <VIcon :icon="currency.icon" size="24px" class="mr-5 text-primary" />
            <VListItemTitle>{{ currency.code }}</VListItemTitle>
          </div>
        </VListItem>
      </VList>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CurrencyDropdown',
  props: {
    toggleTintLayer: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      cookieExpires: Infinity,
      currencies: this.$dom.application.currencies,
      selectedCurrency: null,
      showCurrency: false,
      isDesktopDropdownActive: false
    };
  },
  computed: {
    currency() {
      return this.selectedCurrency || this.$dom.application.currency;
    }
  },
  watch: {
    currency: {
      immediate: true,
      async handler(currency) {
        this.setCurrencyCookie(currency);
      }
    }
  },
  methods: {
    setCurrency(currency) {
      this.selectedCurrency = currency;
      this.setCurrencyCookie(currency);
      this.$reload();
    },
    setCurrencyCookie(currency) {
      this.$cookies.set('currency', currency.code, {
        expires: this.cookieExpires,
        path: '/',
        sameSite: 'None',
        secure: true
      });
    },
    showMobileCurrency() {
      this.showCurrency = !this.showCurrency;
    },
    setDesktopDropdownActive() {
      this.isDesktopDropdownActive = !this.isDesktopDropdownActive;
    },
    handleMenuVisibility(isVisible) {
      this.toggleTintLayer(this, isVisible);
    }
  }
};
</script>

<style lang="scss" scoped>
.arrow {
  position: absolute;
  transform: translate(-50%, -100%);
  top: 0;
  left: 50%;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-bottom: 1rem solid $color-secondary;
}
.v-menu {
  :deep(.v-overlay__content) {
    top: 98px !important;
    // transform: translateX(calc(-50% + 18px));
  }
}

.v-list {
  overflow: visible;
  position: relative;

  .hover {
    border-radius: 8px;
    &:not(.hover-dark) {
      // position: relative;

      // &:after {
      //   content: '';
      // position: absolute;
      // top: 0;
      // left: 0;
      // height: 100%;
      // width: 100%;
      // background: rgba($color-secondary-dark, 0.8);
      // border-radius: 8px;
      // z-index: 0;
      // transform: scale(0);
      // transition: transform $transition-duration $transition-timing;
      // }

      i,
      .v-list-item-title {
        z-index: 1;
        transition: color $transition-duration $transition-timing;
      }

      // @media (hover: hover) {
      //   &:not(:disabled):hover:after {
      //     // transform: scale(1);
      //   }
      // }
    }
  }
}
</style>
