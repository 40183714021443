<template>
  <div class="d-flex w-100 h-100 flex-wrap flex-column align-center ga-3 mt-10">
    <VHover>
      <a v-for="item in filteredItems" :key="item.strapi?.id" :href="item.strapi.Href" class="item-container align-center m-3 d-flex justify-space-between rounded-lg bg-white text-black w-100 h-36px px-5">
        <span class="d-flex w-100 align h-100 align-center button-text">{{ item.strapi.Name }}</span>
        <VIcon class="text-primary align-content-center" :class="item.strapi?.Icon" size="24px" />
      </a>
    </VHover>
  </div>
</template>

<script setup>
import { computed } from 'vue';
/* import ProductCategoriesListCounter from './ProductCategoriesListCounter.vue'; */

const props = defineProps({
  items: {
    type: Array,
    required: true
  }
});

const filteredItems = computed(() => {
  return props.items.filter((item, index) => index !== 2);
});
</script>
<style lang="scss" scoped>
// make a transition for the hover effect
.item-container {
  transition:
    transform $transition-duration ease-in-out,
    box-shadow $transition-duration ease-in-out;
}
.item-container:hover {
  background-color: #f5f5f5 !important;
  cursor: pointer;
  transform: translateY(-2px);
  box-shadow: 0px 7px 15px 1px rgba(100, 100, 100, 0.4) !important;
}

.ios {
  display: -webkit-flex;
}
</style>
