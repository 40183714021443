<script setup>
import { computed } from 'vue';
import { formatDateAsDayMonthYear } from '@/utils/date';

const props = defineProps({
  stockStatus: {
    type: Object,
    required: false,
    default: () => ({
      type: 'out_of_stock'
    })
  },
  noBold: {
    type: Boolean,
    required: false,
    default: false
  }
});

const stockBadge = computed(() => {
  switch (props.stockStatus?.type) {
    case 'in_stock':
      return { style: 'in-stock', text: `In stock${props.stockStatus?.inventory ? ` (${props.stockStatus.inventory} pcs)` : ''}` };
    case 'restock':
      return { style: 're-stock', text: `Back in stock ${formatDateAsDayMonthYear(props.stockStatus?.date)}` };
    case 'orderable':
      return { style: 're-stock', text: 'Orderable' };
    case 'orderable_moq':
      return { style: 're-stock', text: 'Orderable MOQ might apply' };
    case 'discontinued':
      return { style: 'out-of-stock', text: 'Discontinued' };
    default:
      return { style: 'out-of-stock', text: 'Out of Stock' };
  }
});
</script>

<template>
  <!-- TODO: Strapify text, stylize text -->
  <div class="d-flex align-center gap-10 flex-row">
    <div class="stock-badge" :class="stockBadge.style" />
    <p class="paragraph-small my-auto w-auto -no-margin" :class="noBold ? '' : 'bold'">{{ stockBadge.text }}</p>
  </div>
</template>

<style lang="scss" scoped>
.in-stock {
  border-color: $color-validation-green !important;
}

.out-of-stock {
  border-color: $color-validation-red !important;
}

.re-stock {
  border-color: $color-validation-yellow !important;
}

.stock-badge {
  height: 0px;
  width: 0px;
  border-radius: 100%;
  border-width: 6px; // This controls the badge size
  border-style: solid;
  border-color: $color-grey;
}
</style>
