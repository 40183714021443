<template>
  <div class="richText container">
    <div v-if="strapi.PreHeader" class="d-flex">
      <p class="text-primary pr-3 paragraph-large text-align">|</p>
      <p class="pre-header text-align">{{ strapi.PreHeaderText }}</p>
    </div>
    <div class="grid py-5">
      <div class="grid-item -col-span-12 -col-span-md-10 -col-start-md-2" :class="{ '-col-span-lg-7 -col-start-lg-5': strapi.HorizontalAlignment === 'Right', ' -col-span-lg-8 -col-start-lg-3 -col-span-xl-6 -col-start-xl-4': strapi.HorizontalAlignment === 'Center' }">
        <div class="w-100" :class="{ 'expandable h-250px overflow-y-hidden': strapi.MakeExpandable && !expanded, '-expanded': expanded }">
          <div class="d-flex">
            <h2 v-if="strapi.TitleNumber" class="text-primary pr-2">{{ strapi.TitleNumber }}</h2>
            <h2 v-if="strapi.Title" class="text-secondary">{{ strapi.Title }}</h2>
          </div>
          <VueMarkdown v-if="strapi.Text" :source="strapi.Text" :options="options" />
          <Button v-if="strapi.Button && strapi.Button.Href" :href="strapi.Button.Href" icon="fa-regular fa-arrow-right" iconSize="small" iconColor="primary" iconFirst class="mb-4" variant="plain">{{ strapi.Button.Label }}</Button>
        </div>
        <Button v-if="strapi.MakeExpandable" textButton class="mt-4 paragraph-large" :class="{ '-expanded': expanded }" @click="toggleExpand">{{ expanded ? expandLabels.close : expandLabels.open }}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown-render';

export default {
  name: 'RichText',
  components: { VueMarkdown },
  props: {
    strapi: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      options: {
        html: true,
        breaks: true
      },
      expanded: false,
      expandLabels: {
        open: 'View more',
        close: 'View less'
      }
    };
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
    }
  }
};
</script>

<style lang="scss" scoped>
.expandable {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 70%;
    background: linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 1) 100%);
  }

  &.-expanded:after {
    content: none;
  }
}

:deep(h1),
:deep(h2) {
  color: $color-secondary;

  span {
    color: $color-primary;
  }
}

:deep(img) {
  width: 100%;
  border-radius: 8px;
  margin: rem(16) 0;

  @include is-screen-lg() {
    margin: rem(48) 0;
  }
}

:deep(p) {
  @include paragraph('large');
}

:deep(ul),
:deep(ol) {
  @include paragraph('large');
  margin: 0 0 rem(32);
}
// TODO: Remove. Commented 24/2 as it is conflicts with global styles.
// :deep(code) {
//   display: block;
//   background: $color-white;
//   border-radius: 8px;
//   margin-bottom: rem(32);
//   padding: rem(40) rem(48);
// }

:deep(blockquote) {
  p {
    @include blockquote;

    margin: 0 0 rem(32);
  }
}
.pre-header {
  font-size: 1.5rem;
}
</style>
