<template>
  <div
    class="container richText pt-15"
    :class="{
      'pb-6 pb-lg-10': props.strapi.VideoSize === 'Small',
      'pb-8 pb-lg-16': props.strapi.VideoSize !== 'Small'
    }"
  >
    <Card
      class="overflow-visible"
      :class="{
        'bg-secondary': props.strapi.BackgroundColor === 'Dark' && !props.strapi.VideoOutsideContainer,
        'bg-white': props.strapi.BackgroundColor === 'Light' && !props.strapi.VideoOutsideContainer,
        'bg-transparent': props.strapi.BackgroundColor === 'None' || props.strapi.VideoOutsideContainer
      }"
    >
      <div class="grid">
        <div
          class="grid-item -col-span-12"
          :class="{
            '-col-span-md-4': props.strapi.VideoSize === 'Small',
            '-col-span-md-6': props.strapi.VideoSize !== 'Small',
            'align-right': props.strapi.PlacementVideo === 'Right',
            'y-padding-top': props.strapi.BackgroundColor !== 'None',
            'x-padding-right': props.strapi.PlacementVideo === 'Right' && props.strapi.BackgroundColor !== 'None',
            'x-padding-left': props.strapi.PlacementVideo !== 'Right' && props.strapi.BackgroundColor !== 'None'
          }"
        >
          <video v-if="props.strapi.Video.VideoFile" class="w-100 rounded-lg elevation-21" :controls="props.strapi.Video.ShowControls" :autoplay="props.strapi.Video.AutoPlay" :muted="props.strapi.Video.AutoPlay || props.strapi.Muted" :poster="props.strapi.Video.PosterImage?.url || ''" :loop="props.strapi.Video.Loop">
            <source :src="props.strapi.Video.VideoFile.url" />
          </video>
        </div>
        <div
          ref="textBlockRef"
          class="text-block grid-item -col-span-12 d-flex flex-column"
          :class="{
            '-col-span-md-8': props.strapi.VideoSize === 'Small',
            '-col-span-md-6 ': props.strapi.VideoSize !== 'Small',
            'y-padding-bottom': props.strapi.BackgroundColor !== 'None',
            'x-padding-left': props.strapi.PlacementVideo === 'Right' && props.strapi.BackgroundColor !== 'None',
            'x-padding-right': props.strapi.PlacementVideo !== 'Right' && props.strapi.BackgroundColor !== 'None',
            'justify-center': props.strapi.BackgroundColor === 'None',
            'pr-md-4 pr-lg-10': props.strapi.PlacementVideo === 'Right' && props.strapi.BackgroundColor === 'None',
            'pl-md-4 pl-lg-10': props.strapi.PlacementVideo !== 'Right' && props.strapi.BackgroundColor === 'None',
            'h-100': !props.strapi.VideoOutsideContainer
          }"
        >
          <span
            v-if="props.strapi.VideoOutsideContainer && props.strapi.BackgroundColor !== 'None'"
            ref="textBgRef"
            class="text-block-bg rounded-lg"
            :class="{
              'bg-secondary': props.strapi.BackgroundColor === 'Dark',
              'bg-white': props.strapi.BackgroundColor === 'Light'
            }"
          />
          <div class="mb-5">
            <h2 class="h1" :class="{ 'text-secondary': props.strapi.BackgroundColor !== 'Dark', 'text-white': props.strapi.BackgroundColor === 'Dark' }">{{ props.strapi.Title }}</h2>
            <div class="paragraph-large" :class="{ 'text-black': props.strapi.BackgroundColor !== 'Dark', 'text-white': props.strapi.BackgroundColor === 'Dark' }"><VueMarkdown v-if="props.strapi.Text" :source="props.strapi.Text" :options="options" /></div>
          </div>
          <div v-for="link in props.strapi.Link" :key="link" class="mb-3" :class="{ 'd-flex flex-1-0 align-end': props.strapi.BackgroundColor !== 'None' }">
            <Button v-if="link.Href" icon="fa-regular fa-arrow-right" iconFirst iconColor="primary" variant="plain" :href="link.Href" :color="props.strapi.BackgroundColor === 'Dark' ? 'white' : 'black'">{{ link.LinkLabel }}</Button>
          </div>
        </div>
      </div>
    </Card>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import VueMarkdown from 'vue-markdown-render';
import { throttle } from 'throttle-debounce';

const props = defineProps({
  strapi: {
    type: Object,
    default: () => {},
    required: true
  }
});

const options = {
  html: true,
  breaks: true
};

onMounted(() => {
  setBgHeight();
  window.addEventListener('resize', throttledEventListener);
});
onUnmounted(() => {
  window.removeEventListener('resize', throttledEventListener);
});

function setBgHeight() {
  const textBgRef = ref(null);

  if (!textBgRef.value) return;
  let bgHeight = 0;
  if (window.innerWidth > 959) {
    bgHeight = `${textBlockRef.value.clientHeight}px`;
  } else {
    bgHeight = '100%';
  }
  textBgRef.value.style.height = bgHeight;
}

function throttledEventListener() {
  throttle(300, this.setBgHeight());
}
</script>

<style lang="scss" scoped>
.video-card {
  -webkit-box-sizing: unset;
  -moz-box-sizing: unset;
  box-sizing: unset;
}
.align-right {
  @include is-screen-md() {
    order: 2;
  }
}

.text-block-bg {
  position: absolute;
  width: 100%;
  display: block;
  z-index: -1;
  left: 0;
  top: 0;
}
.y-padding-top {
  padding-top: 24px;

  @include is-screen-md() {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include is-screen-lg() {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.y-padding-bottom {
  padding-bottom: 24px;

  @include is-screen-md() {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  @include is-screen-lg() {
    padding-bottom: 80px;
    padding-top: 80px;
  }
}
.x-padding-right {
  padding-right: 24px;
  padding-left: 24px;

  @include is-screen-md() {
    padding-right: 40px;
    padding-left: 8px;
  }

  @include is-screen-lg() {
    padding-right: 80px;
    padding-left: 20px;
  }
}

.x-padding-left {
  padding-left: 24px;
  padding-right: 24px;

  @include is-screen-md() {
    padding-left: 40px;
    padding-right: 8px;
  }

  @include is-screen-lg() {
    padding-left: 80px;
    padding-right: 20px;
  }
}

:deep(p) {
  @include paragraph('large');
}
</style>
