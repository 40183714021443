<template>
  <div v-if="$isNotBlank(salesReason)" class="bg-secondary py-1 px-2 paragraph-small sales-reason">{{ salesReason }}</div>
</template>
<script>
export default {
  name: 'SalesReasonBanner',
  props: {
    salesReason: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
@include is-screen-md() {
  .sales-reason {
    position: absolute;
    z-index: 10;
    border-radius: 5px;
  }
}
</style>
